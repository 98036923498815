<template>
    <div class="manyList">
        <!-- 目前已到5 -->
        <div class="manyListtop">
            <el-input v-model="name" :placeholder="styleMethon == 0 || styleMethon == 11
                ? '请输入商品名称'
                : styleMethon == 1
                    ? '请输入商户名称'
                    : styleMethon == 2
                        ? '请输入商户分类名称'
                        : styleMethon == 3
                            ? '请输入文章名称'
                            : styleMethon == 4
                                ? '请输入文章分类名称'
                                : styleMethon == 5
                                    ? '请输入会员名称'
                                    : styleMethon == 6
                                        ? '请输入用户名称'
                                        : styleMethon == 7
                                            ? '请输入用户名称'
                                            : styleMethon == 8
                                                ? '请输入'
                                                : styleMethon == 9
                                                    ? '请输入用户名称'
                                                    : styleMethon == 10 || styleMethon == 12
                                                        ? '请输入服务名称'
                                                        : ''
                "></el-input>
            <el-input v-if="styleMethon == 6" v-model="mobile" placeholder="请输入手机号码"></el-input>
            <el-button type="primary" @click="getGoods(name)">搜索</el-button>
        </div>
        <div class="manyListbot">
            <div v-for="(item, index) in list" :key="index">
                <div class="imgbox"
                    v-if="styleMethon == 0 || styleMethon == 5 || styleMethon == 6 || styleMethon == 7 || styleMethon == 8 || styleMethon == 9 || styleMethon == 10 || styleMethon == 11 || styleMethon == 12">
                    <img :src="styleMethon == 0
                        ? item.goods_picture
                        : styleMethon == 8
                            ? item.user_info.avatar
                            : styleMethon == 10
                                ? item.cover_picture
                                : styleMethon == 11
                                    ? item.goods_cover_picture
                                    : styleMethon == 12
                                        ? item.service_cover_picture
                                        : item.avatar
                        " alt="" />
                </div>
                <div class="name">
                    {{
                        styleMethon == 0 || styleMethon == 11
                        ? item.goods_name
                        : styleMethon == 1
                            ? item.store_name
                            : styleMethon == 2
                                ? item.class_name
                                : styleMethon == 3
                                    ? item.title
                                    : styleMethon == 4
                                        ? item.classify_name
                                        : styleMethon == 5 || styleMethon == 6 || styleMethon == 7 || styleMethon == 9
                                            ? item.nickname
                                            : styleMethon == 8
                                                ? item.user_info.nickname
                                                : styleMethon == 10 || styleMethon == 12
                                                    ? item.service_name
                                                    : ''
                    }}
                </div>
                <div class="price" v-if="styleMethon == 0 || styleMethon == 10 || styleMethon == 11 || styleMethon == 12">
                    ￥{{ styleMethon == 0 || styleMethon == 11 ? item.goods_price : item.service_price }}
                </div>
                <div class="mobile" v-if="styleMethon == 5">{{ item.mobile }}</div>
                <div class="choose" @click="chooseItem(item)">选择</div>
            </div>
        </div>
        <Paging :total="total" :page="page" :pageNum="pageNum" @updatePageNum="updateData"></Paging>
    </div>
</template>

<script>
import Paging from './paging';
export default {
    props: {
        styleMethon: {
            type: Number,
            default: () => -1,
        },
        comefrom: {
            type: Number,
            default: 1,
        },
    },
    components: {
        Paging,
    },
    data () {
        return {
            name: '',
            list: [],
            page: 1,
            pageNum: 10,
            total: 0,
            flag: true,
            mobile: ''
        };
    },
    created () {
        this.getGoods();
    },
    methods: {
        chooseItem (item) {
            this.$emit('getListItem', item);
        },
        // 分页参数
        updateData (val, status) {
            if (status == 0) {
                this.pageNum = val;
            } else {
                this.page = val;
            }
            if (this.flag) {
                this.getGoods();
            } else {
                this.getGoods(this.name);
            }
        },
        getGoods (val) {
            let that = this;
            let url = '';
            console.log(this.styleMethon);
            if (this.styleMethon == 0) {
                url = this.$api.goods.list;
            } else if (this.styleMethon == 1) {
                url = this.$api.store.storeList;
            } else if (this.styleMethon == 3) {
                url = this.$api.set.listArticle;
            } else if (this.styleMethon == 4) {
                url = this.$api.set.listArticleClass;
            } else if (this.styleMethon == 5) {
                url = this.$api.user.userList;
            } else if (this.styleMethon == 6) {
                url = this.$api.distribution.userList;
            } else if (this.styleMethon == 7) {
                url = this.$api.O2O.regionalAdministrator.getUserList;
            } else if (this.styleMethon == 8) {
                url = this.$api.push.groupUserList;
            } else if (this.styleMethon == 9) {
                url = this.$api.retail.userList;
            } else if (this.styleMethon == 10) {
                url = this.$api.beauty.service.serviceList;
            } else if (this.styleMethon == 11) {
                url = '/Gateway' + this.$api.goods.list;
            } else if (this.styleMethon == 12) {
                url = this.$api.gateway.service.serviceList;
            } else if (this.styleMethon == 13) {
                url = this.$api.gateway.member.list;
            }
            if (val) {
                this.flag = false;
                if (this.styleMethon == 0) {
                    this.$axios
                        .post(url, {
                            page: that.page,
                            rows: that.pageNum,
                            is_putaway: 2,
                            goods_name: this.name,
                        })
                        .then(res => {
                            if (res.code == 0) {
                                that.list = res.result.list;
                                that.total = res.result.total_number;
                            }
                        });
                } else if (this.styleMethon == 1) {
                    this.$axios
                        .post(url, {
                            page: that.page,
                            rows: that.pageNum,
                            store_name: this.name,
                        })
                        .then(res => {
                            if (res && res.code == 0) {
                                that.list = res.result.list;
                                that.total = res.result.total_number;
                            }
                        });
                } else if (this.styleMethon == 3) {
                    this.$axios
                        .post(url, {
                            page: that.page,
                            rows: that.pageNum,
                            title: this.name,
                            is_show: 2,
                        })
                        .then(res => {
                            if (res && res.code == 0) {
                                that.list = res.result.list;
                                that.total = res.result.total_number;
                            }
                        });
                } else if (this.styleMethon == 4) {
                    this.$axios
                        .post(url, {
                            page: that.page,
                            rows: that.pageNum,
                            classify_name: this.name,
                        })
                        .then(res => {
                            if (res && res.code == 0) {
                                that.list = res.result.list;
                                that.total = res.result.total_number;
                            }
                        });
                } else if (this.styleMethon == 5) {
                    this.$axios
                        .post(url, {
                            page: that.page,
                            rows: that.pageNum,
                            keyword: that.name,
                            is_select_func: 1,
                        })
                        .then(res => {
                            if (res.code == 0) {
                                that.list = res.result.list;
                                that.total = res.result.total;
                            }
                        });
                } else if (this.styleMethon == 6) {
                    this.$axios
                        .post(url, {
                            page: that.page,
                            rows: that.pageNum,
                            nickname: that.name,
                            comefrom: that.comefrom,
                            mobile: that.mobile
                        })
                        .then(res => {
                            if (res.code == 0) {
                                that.list = res.result.list;
                                that.total = res.result.total_number;
                            }
                        });
                } else if (this.styleMethon == 7) {
                    this.$axios
                        .post(url, {
                            page: that.page,
                            rows: that.pageNum,
                            name: that.name,
                        })
                        .then(res => {
                            if (res.code == 0) {
                                that.list = res.result.user_list;
                                that.total = res.result.total_number;
                            }
                        });
                } else if (this.styleMethon == 8) {
                    this.$axios
                        .post(url, {
                            page: that.page,
                            rows: that.pageNum,
                            name: that.name,
                        })
                        .then(res => {
                            if (res.code == 0) {
                                that.list = res.result.user_list;
                                that.total = res.result.total_number;
                            }
                        });
                } else if (this.styleMethon == 9) {
                    this.$axios
                        .post(url, {
                            page: that.page,
                            rows: that.pageNum,
                            keyword: that.name,
                        })
                        .then(res => {
                            if (res.code == 0) {
                                that.list = res.result.list;
                                res.result.list.map(item => {
                                    if (item.avatar === '') item.avatar = 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/enterpriseShop/beauty/defaultHeaderImg.png';
                                    if (item.nickname === '') item.nickname = item.mobile;
                                });
                                that.total = res.result.total;
                            }
                        });
                } else if (this.styleMethon == 10) {
                    this.$axios
                        .post(url, {
                            page: that.page,
                            rows: that.pageNum,
                            service_name: that.name,
                            status: 2,
                        })
                        .then(res => {
                            if (res.code == 0) {
                                that.list = res.result.list;
                                that.total = res.result.total_number;
                            }
                        });
                }
            } else {
                let obj = {};
                if (this.styleMethon == 0) {
                    obj = {
                        page: that.page,
                        rows: that.pageNum,
                        is_putaway: 2,
                    };
                } else if (this.styleMethon == 3) {
                    obj = {
                        page: that.page,
                        rows: that.pageNum,
                        is_show: 2,
                    };
                } else if (this.styleMethon == 5) {
                    obj = {
                        page: that.page,
                        rows: that.pageNum,
                        is_select_func: 1,
                    };
                } else if (this.styleMethon == 6) {
                    obj = {
                        page: that.page,
                        rows: that.pageNum,
                        nickname: that.name,
                        comefrom: that.comefrom,
                        mobile: that.mobile
                    };
                } else if (this.styleMethon == 8) {
                    obj = {
                        page: that.page,
                        rows: that.pageNum,
                        nickname: that.name,
                    };
                } else if (this.styleMethon == 10) {
                    obj = {
                        page: that.page,
                        rows: that.pageNum,
                        status: 2,
                    };
                } else {
                    obj = {
                        page: that.page,
                        rows: that.pageNum,
                    };
                }
                this.$axios.post(url, obj).then(res => {
                    if (res.code == 0) {
                        if (this.styleMethon == 7) {
                            that.list = res.result.user_list;
                        } else {
                            if (this.styleMethon == 9) {
                                res.result.list.map(item => {
                                    if (item.avatar === '') item.avatar = 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/enterpriseShop/beauty/defaultHeaderImg.png';
                                    if (item.nickname === '') item.nickname = item.mobile;
                                });
                            }
                            that.list = res.result.list;
                        }
                        if (this.styleMethon == 5 || this.styleMethon == 9) {
                            that.total = res.result.total;
                        } else {
                            that.total = res.result.total_number;
                        }
                    }
                });
            }
        },
    },
};
</script>

<style lang="less" scoped>
.manyList {
    width: 100%;
    min-height: 400px;

    .manyListtop {
        display: flex;

        .el-input {
            margin-right: 10px;
        }
    }

    .manyListbot {
        width: 100%;
        margin-top: 20px;
        height: 360px;
        overflow: hidden;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
            /**/
        }

        &::-webkit-scrollbar-track {
            background: #fff;
            border-radius: 2px;
        }

        &::-webkit-scrollbar-thumb {
            background: rgba(0, 0, 0, 0.1);
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #f9f9f9;
        }

        &::-webkit-scrollbar-corner {
            background: #204754;
        }

        &>div {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            min-height: 50px;

            .imgbox {
                width: 80px;
                height: 80px;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .name {
                flex: 1;
                padding-left: 20px;
                height: 100%;
            }

            .price {
                width: 80px;
                height: 100%;
            }

            .mobile {
                width: 120px;
                height: 100%;
            }

            .choose {
                width: 80px;
                height: 100%;
                color: #3772ff;
                cursor: pointer;
            }
        }
    }
}
</style>
